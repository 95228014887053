"use client";

import Image from "next/image";
import Link from "next/link";
import { useAppContext } from "@/context/Context";
import React, { useRef, useEffect, useState } from "react";
import { useFormState } from "react-dom";
import sal from "sal.js";

import boxedLogo from "../../public/images/logo/boxed-logo.png";
import google from "../../public/images/sign-up/google.png";
import facebook from "../../public/images/sign-up/facebook.png";
import PageHead from "@/pages/Head";
// import { signUp } from '@/app/backend/auth';
import { toast } from "react-toastify";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
const UserAuth = () => {
  const router = useRouter();
  const { toggleAuth, setToggleAuth } = useAppContext();
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    sal();

    const cards = document.querySelectorAll(".bg-flashlight");

    cards.forEach((bgflashlight) => {
      bgflashlight.onmousemove = function (e) {
        let x = e.pageX - bgflashlight.offsetLeft;
        let y = e.pageY - bgflashlight.offsetTop;

        bgflashlight.style.setProperty("--x", x + "px");
        bgflashlight.style.setProperty("--y", y + "px");
      };
    });
  }, []);

  const loginFormRef = useRef(null);
  const signinFormRef = useRef(null);

  useEffect(() => {
    if (toggleAuth) {
      loginFormRef.current?.reset();
    } else {
      signinFormRef.current?.reset();
    }
  }, [toggleAuth]);

  const initialState = { message: null, errors: {}, data: {}, success: false };
  const [signUpApiResponse, setSignUpApiResponse] = useState(initialState);
  const [signInApiResponse, setSignInApiResponse] = useState(initialState);

  async function onSubmitSignup(event) {
    event.preventDefault();
    setSubmit(true);
    const formData = new FormData(event.currentTarget);
    const formDataEntries = formData.entries();
    const formDataObj = {};
    for (const [key, value] of formDataEntries) {
      formDataObj[key] = value;
    }

    setSignUpApiResponse((prevState) => ({
      ...prevState,
      message: null,
      errors: {},
      data: {},
      success: false,
    }));

    try {
      const response = await fetch("/api/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataObj),
      });

      // Handle response if necessary
      const data = await response.json();

      if (!response.ok) {
        if (response.status == 400) {
          throw data;
        } else if (response.status == 500) {
          throw data.message;
        } else {
          throw "Failed to register. Please try again.";
        }
      }
      // toast(data.message);
      console.log("For reference sign up ", data);
      const userId = data.data.user.id;
      const role = data.data.user.role;
      localStorage.setItem("userid", JSON.stringify(userId));
      localStorage.setItem("role", role);

      const result = await signIn("credentials", {
        email: formDataObj.email,
        password: formDataObj.password,
        redirect: false,
      });
      console.log("result from signin", result);
      router.push("/");
      setToggleAuth(!toggleAuth);

    } catch (error) {
      console.error("Error:", error);
      // Handle error and return appropriate response
      if (typeof error === "object") {
        if (error.errors?.hasOwnProperty("validation_errors")) {
          let errorObj = error;
          setSignUpApiResponse((prevState) => ({
            ...prevState,
            errors: {
              ...prevState.errors,
              validation_errors: errorObj.errors.validation_errors,
            },
            message: errorObj.message,
          }));
        } else {
          toast("Something went wrong");
        }
      } else if (typeof error === "string") {
        toast(error);
      } else {
        toast("Something went wrong");
      }
    }
    setSubmit(false);
  }

  async function onSubmitSignIn(event) {
    event.preventDefault();
    setSubmit(true);

    const formData = new FormData(event.currentTarget);
    const formDataEntries = formData.entries();
    const formDataObj = {};
    for (const [key, value] of formDataEntries) {
      formDataObj[key] = value;
    }

    console.log(formDataObj);

    setSignInApiResponse((prevState) => ({
      ...prevState,
      message: null,
      errors: {},
      data: {},
      success: false,
    }));

    try {
      const response = await fetch("/api/signin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataObj),
      });

      // Handle response if necessary
      const data = await response.json();
      if (!response.ok) {
        if (response.status == 400) {
          throw data;
        } else if (response.status == 500) {
          throw data.message;
        } else {
          throw "Failed to login. Please try again.";
        }
      }

      const userId = data.data.user.id;
      const role = data.data.user.role;
      localStorage.setItem("userid", JSON.stringify(userId));
      localStorage.setItem("role", role);
      const result = await signIn("credentials", {
        email: formDataObj.email,
        password: formDataObj.password,
        redirect: false,
      });

      if (result) {
        if (result.error) {
          console.log(result.error);
          throw "Failed to login. Please try again.";
        } else {
          console.log("Login successful!");
          toast("Login successful!");

          router.push("/");
        }
      } else {
        throw "Failed to login. Please try again.";
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error and return appropriate response
      if (typeof error === "object") {
        if (error.errors?.hasOwnProperty("validation_errors")) {
          let errorObj = error;
          setSignInApiResponse((prevState) => ({
            ...prevState,
            errors: {
              ...prevState.errors,
              validation_errors: errorObj.errors.validation_errors,
            },
            message: errorObj.message,
          }));
        } else {
          toast("Something went wrong");
        }
      } else if (typeof error === "string") {
        toast(error);
      } else {
        toast("Something went wrong");
      }
    }
    setSubmit(false);
  }

  return (
    <>
      <PageHead title={`${toggleAuth ? "Sign In" : "SignUp"}`} />
      <div
        className="signup-area rainbow-section-gapTop-big"
        data-black-overlay="2"
      >
        <div className="sign-up-wrapper rainbow-section-gap">
          <div className="sign-up-box bg-flashlight">
            <div className="signup-box-top top-flashlight light-xl">
              <Image
                src={boxedLogo}
                width={200}
                height={100}
                alt="sign-up logo"
              />
            </div>
            <div className="separator-animated animated-true"></div>
            <div className="signup-box-bottom">
              <div className="signup-box-content">
                <h4 className="title">{toggleAuth ? "Sign In" : "Sign up"}</h4>

                {toggleAuth ? (
                  <form onSubmit={onSubmitSignIn} ref={loginFormRef} method="POST">
                    <div className="input-section mail-section">
                      <div className="icon">
                        <i className="feather-mail"></i>
                      </div>
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                      />
                    </div>
                    {signInApiResponse.errors.validation_errors?.email &&
                      signInApiResponse.errors.validation_errors.email.map(
                        (error) => (
                          <span className="text-danger" key={error}>
                            {error}
                          </span>
                        )
                      )}
                    <div className="input-section password-section">
                      <div className="icon">
                        <i className="feather-lock"></i>
                      </div>
                      <input
                        type="password"
                        name="password"
                        placeholder="Password"
                      />
                    </div>
                    {signInApiResponse.errors.validation_errors?.password &&
                      signInApiResponse.errors.validation_errors.password.map(
                        (error) => (
                          <span className="text-danger" key={error}>
                            {error}
                          </span>
                        )
                      )}
                    <div className="forget-text">
                      <Link className="btn-read-more" href="/forgot-password">
                        <span>Forgot password</span>
                      </Link>
                    </div>
                    <button type="submit" className="btn-default btn-bg-purple">
                      {submit ? "Signing In ..." : "Sign In"}
                    </button>
                  </form>
                ) : (
                  <form onSubmit={onSubmitSignup} ref={signinFormRef} method="POST">
                    <input type="hidden" name="username" value="nab@gmail.com" />
                    <div className="input-section mail-section">
                      <div className="icon">
                        <i className="feather-user"></i>
                      </div>
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter Your Name"
                      />
                    </div>
                    {signUpApiResponse.errors.validation_errors?.name &&
                      signUpApiResponse.errors.validation_errors.name.map(
                        (error) => (
                          <span className="text-danger" key={error}>
                            {error}
                          </span>
                        )
                      )}
                    <div className="input-section mail-section">
                      <div className="icon">
                        <i className="feather-mail"></i>
                      </div>
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                      />
                    </div>
                    {signUpApiResponse.errors.validation_errors?.email &&
                      signUpApiResponse.errors.validation_errors.email.map(
                        (error) => (
                          <span className="text-danger" key={error}>
                            {error}
                          </span>
                        )
                      )}
                    <div className="input-section password-section">
                      <div className="icon">
                        <i className="feather-lock"></i>
                      </div>
                      <input
                        type="password"
                        name="password"
                        placeholder="Create Password"
                      />
                    </div>
                    {signUpApiResponse.errors.validation_errors?.password &&
                      signUpApiResponse.errors.validation_errors.password.map(
                        (error) => (
                          <span className="text-danger" key={error}>
                            {error}
                          </span>
                        )
                      )}
                    <div className="input-section password-section">
                      <div className="icon">
                        <i className="feather-lock"></i>
                      </div>
                      <input
                        type="password"
                        name="password_confirmation"
                        placeholder="Confirm Password"
                      />
                    </div>
                    {signUpApiResponse.errors.validation_errors
                      ?.password_confirmation &&
                      signUpApiResponse.errors.validation_errors.password_confirmation.map(
                        (error) => (
                          <p className="text-danger m-0" key={error}>
                            {error}
                          </p>
                        )
                      )}
                    <button type="submit" className="btn-default">
                      {submit ? "Signing Up ..." : "Sign Up"}
                    </button>
                  </form>
                )}
              </div>
              <div className="signup-box-footer">
                <div className="bottom-text">

                  {toggleAuth ? <span>Don&apos;t have an account?</span> : <span>Have an account?</span>}
                  <Link
                    className="btn-read-more ps-2 cursor-pointer"
                    onClick={() => setToggleAuth(!toggleAuth)}
                    href={`javascript:void(0)`}
                  >
                    <span> {toggleAuth ? 'Sign Up' : 'Sign In'} </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAuth;
